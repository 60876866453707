<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.dispatch('toggleAdminSidebar')"
    />
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :to="{ name: 'home' }">
          {{ $t("admin.to_main") }}
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CSubheader class="px-3" v-if="breadcrumbs">
      <Breadcrumbs class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import { mapActions } from "vuex";
import Breadcrumbs from "@/components/admin/Breadcrumbs";

export default {
  components: { Breadcrumbs },

  props: {
    breadcrumbs: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    ...mapActions(["toggleAdminSidebar"])
  }
};
</script>
