<template>
  <CBreadcrumb v-bind="props">
    <slot></slot>
  </CBreadcrumb>
</template>

<script>
export default {
  computed: {
    items() {
      const routes = this.$route.matched.filter(route => {
        return route.name || (route.meta && route.meta.breadcrumb);
      });
      return routes.map(route => {
        const meta = route.meta || {};

        for (const [key, value] of Object.entries(this.$route.params)) {
          route.path = route.path.replace(`:${key}`, value);
        }

        return {
          to: route,
          text: this.$t(meta.breadcrumb) || route.name
        };
      });
    },
    props() {
      return {
        items: this.items
      };
    }
  }
};
</script>
